import "./index.css"
import img from "../../assets/images/4.png"
import Theme_Buttom from "../general_components/button"

export default function NewHome() {
    return (
        <div className="newhome_container_bg_wrapper">

            <div className="newhome_container_bg theme_container">
                <div className="newhome_container ">
                    <div className="newhome_content">
                        <h1>New Home, Your Location</h1>
                        <p>Milenium Haus gives you a seamless experience when refinancing your home. If you want to reduce your debt, eliminate your mortgage or become financially free, you’re in the right place. If you’re in The Bay Area or Toronto, we help you do this in a matter of months. </p>
                        <Theme_Buttom to={"/ownership"} text={"Learn More"} />
                    </div>
                    <div className="newhome_image">
                        <img src={img} alt="" />
                    </div>
                </div>
            </div>
        </div>


    )
}