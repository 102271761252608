import './index.css'
import { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'
import cross from '../../../assets/images/cross.png'
import TextField from '@mui/material/TextField'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import { setLoginData } from '../../../actions/loginAction'
import { useNavigate } from 'react-router-dom'

export default function SignUpModal({ isOpenModal, setIsOpenModal }) {
  const [isOpen, setIsOpen] = useState(isOpenModal)
  // const [isErrorMsg, setIsErrorMsg] = useState('')
  const [isError, setIsError] = useState('')
  const [success, setSuccess] = useState(false)
  const [codeSend, setCodeSend] = useState(false)
  const [verifyCode, setVerifyCode] = useState('')
  const [loader, setLoader] = useState(false)
  const [mailValid, setMailValid] = useState(true)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    email: '',
  }
  const [values, setValues] = useState(initialValues)

  const defaultState = {
    email: false,
  }

  const [touched, setTouched] = useState(defaultState)

  const handleFocus = (e) => {
    setTouched({
      ...touched,
      email: true,
    })
  }

  const enteredEmailIsValid =
    values.email.trim() !== '' &&
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)

  const emailInputIsInvalid = touched.email && !enteredEmailIsValid

  function toggleModal(state) {
    if (state === false) setIsOpenModal(state)
    setIsOpen(state)
  }

  useEffect(() => {
    setCodeSend(false)
    setValues(initialValues)
    setTouched(defaultState)
    setIsError('')
    if (isOpenModal === true) toggleModal(isOpenModal)
    setVerifyCode('')
  }, [isOpenModal])

  const handleinputchange = (e) => {
    // setIsErrorMsg('')
    if (
      e.target.value.trim() !== '' &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      setMailValid(false)
    } else {
      setMailValid(true)
    }

    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const onSubmitHandler = (event) => {
    // console.log("oka")
    event.preventDefault()
    setTouched({
      email: true,
    })

    if (!enteredEmailIsValid) {
      return
    }

    let data = {
      email: values.email,
    }

    const config = {
      headers: {
        Accept: '*/*',
        'Content-Type': 'application/json',
      },
    }
    setLoader(true)
    setTimeout(() => {
      // if (!codeSend) {
      axios
        .post('/api/users/request-code', data, config)
        .then((response) => {
          if (response.data.status === 200) {
            setSuccess(true)
            setCodeSend(true)
            setLoader(false)
            // setValues(initialValues)
            setTouched(defaultState)
          } else if (response.data.status === 422) {
            setIsError(response.data.message)
            setTouched(defaultState)
            setLoader(false)
          }
        })
        .catch((err) => {})
      // }
    }, 1000)
  }

  useEffect(() => {
    setIsError('')
    if (verifyCode.length === 6) {
      const config = {
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
        },
      }
      let data = {
        email: values.email,
        code: verifyCode,
      }
      setLoader(true)
      setTimeout(() => {
        axios
          .post('/api/users/verify-code', data, config)
          .then((response) => {
            if (response.data.status === 200) {
              setValues(initialValues)
              setCodeSend(false)
              setVerifyCode('')
              setTouched(defaultState)
              dispatch(setLoginData(response.data))
              setIsOpen(false)
              navigate('/profile')
              setLoader(false)
              localStorage.setItem('access_token', response.data.access_token)
              localStorage.setItem('role', response.data.role)
            } else if (response.data.status === 400) {
              setIsError(response.data.message)
              setTouched(defaultState)
              setLoader(false)
            }
          })
          .catch((err) => {})
      }, 1000)
    }
  }, [verifyCode])

  return (
    <>
      {' '}
      <Modal
        isOpen={isOpen}
        onRequestClose={() => toggleModal(false)}
        contentLabel="My dialog"
        className="mymodal_2 sign_up_modal"
        overlayClassName="myoverlay"
        closeTimeoutMS={400}
      >
        {!codeSend ? (
          <div className="Modal_content__iXQTC max-w-modal-xs sm:max-w-xl p-0">
            <button
              data-id="sign-in-modal-close-button"
              className="sign-in-modal-close-button absolute right-0 top-0 p-7/8 sm:p-1-1/2 z-modal-overlay Modal_close-button__bKbGl"
              type="button"
              aria-label="Close Dialog"
              onClick={() => {
                setIsOpen(false)
                setValues(initialValues)
              }}
            >
              <svg
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                className="icon text-primary-light stroke-current w-1"
              >
                <path fill="none" d="M1 1l12 12M13 1L1 13"></path>
              </svg>
            </button>
            <div
              className="bg-tan-100 px-1-1/2 sm:px-3 pt-4 pb-1-3/8"
              data-id="signup-signin-wrapper"
            >
              <div className="flex flex-col items-center">
                <h1 className="type-subhead text-primary font-body">
                  Sign up or sign in
                </h1>
                <h2
                  className="mt-1/2 mb-1 text-center px-1 lg:px-0"
                  data-id="signup-signin-title"
                >
                  Welcome to Milenium Haus
                </h2>
                <p
                  className="text-center mb-1"
                  data-id="signup-signin-description"
                >
                  Unlock full pricing details, personalize your experience, save
                  favorites and more.
                </p>
                <p className="type-error-lg"></p>
                <div>
                  <form
                    className="flex flex-col items-center w-full sm:w-auto"
                    name="signup-signin-form"
                    style={{ minWidth: '16rem' }}
                    onSubmit={onSubmitHandler}
                  >
                    <div
                      className="w-full sm:w-auto"
                      style={{ minWidth: '16rem' }}
                    >
                      <div className="relative grid grid-cols-1 Input_container__d8Lnr">
                        <TextField
                          className="sign_up_text_field"
                          style={{ width: '80%' }}
                          margin="normal"
                          inputProps={{
                            autoComplete: 'new-password',
                            form: {
                              autoComplete: 'off',
                            },
                          }}
                          type="email"
                          required=""
                          pattern="[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}"
                          label="Enter your email address*"
                          name="email"
                          value={values.email}
                          onBlur={handleFocus}
                          error={emailInputIsInvalid}
                          onChange={handleinputchange}
                          helperText={
                            emailInputIsInvalid
                              ? 'Please enter valid email'
                              : ' '
                          }
                        />
                      </div>
                      <button
                        className="request-btn w-full mt-1-1/2 Button_button-text__PhP_s Button_button-accent__c_4lw Button_button__XQZs8 Button_size-normal__7MMkC"
                        type="submit"
                        disabled={mailValid || loader ? true : false}
                        onClick={onSubmitHandler}
                      >
                        {!loader && <span>Continue with email</span>}
                        {loader && (
                          <div className="Spinner_loader__xHE9D Spinner_bg-accent-lighter__gxdpU Spinner_loader-accent__AnZPE w-1-1/2 h-1-1/2 absolute">
                            <span></span>
                          </div>
                        )}
                      </button>
                    </div>
                  </form>
                </div>
                <p className="type-legal text-primary-light mt-1-1/2 mb-3 text-center max-w-xxl">
                  I give Milenium Haus permission to contact me &amp; agree to
                  the{' '}
                  <a
                    className="StyledLink_inherit-inline-underline__200BH"
                    href="/terms-and-conditions"
                    target="_blank"
                  >
                    terms
                  </a>
                  . This site is protected by reCAPTCHA and the Google&nbsp;{' '}
                  <a
                    className="StyledLink_inherit-inline-underline__200BH"
                    target="_blank"
                    href="/privacy-policy"
                  >
                    Privacy Policy
                  </a>
                  ,&nbsp;{' '}
                  <a
                    className="StyledLink_inherit-inline-underline__200BH"
                    target="_blank"
                    href="https://policies.google.com/terms"
                  >
                    terms of service
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="Modal_container__SC_Vs verify_code_modal"
            tabindex="-1"
            role="dialog"
            aria-modal="true"
          >
            <button
              className="absolute left-0 top-0 p-3/4 sm:p-1-1/2 z-modal-overlay Modal_back-button__pLObi"
              type="button"
              aria-label="Go Back"
              onClick={() => {
                setCodeSend(false)
                setVerifyCode('')
                setIsError('')
                setLoader(false)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 16.719 16.566"
                className="icon text-primary-light stroke-current w-1-1/2"
              >
                <g fill="none">
                  <path d="M15.719 8.283H1M7.869 1.415L1 8.283l6.869 6.869"></path>
                </g>
              </svg>
            </button>
            <button
              data-id="sign-in-modal-close-button"
              className="absolute right-0 top-0 p-7/8 sm:p-1-1/2 z-modal-overlay Modal_close-button__bKbGl"
              type="button"
              aria-label="Close Dialog"
              onClick={() => {
                setIsOpen(false)
              }}
            >
              <svg
                viewBox="0 0 14 14"
                xmlns="http://www.w3.org/2000/svg"
                className="icon text-primary-light stroke-current w-1"
              >
                <path fill="none" d="M1 1l12 12M13 1L1 13"></path>
              </svg>
            </button>
            <div className="Modal_content__iXQTC max-w-modal-xs sm:max-w-xl p-0">
              <form
                className="bg-tan-100 flex flex-col px-1-1/2 sm:px-3 pt-5-1/8 pb-4-1/8 justify-center items-center"
                name="verify-code-form"
                onSubmit={onSubmitHandler}
              >
                <p className="type-subhead text-primary">Check your email</p>
                <h3
                  id="label-22"
                  className="mt-1/2 mb-1 text-center px-1 sm:px-0"
                >
                  Enter the 6-digit code we emailed you
                </h3>
                <div className="mb-1-1/2">
                  <input
                    className={`CodeInputMask_input__ZrXey ${
                      isError ? 'err' : ''
                    }`}
                    id="input-"
                    placeholder="- - - - - -"
                    aria-labelledby="label-22"
                    autoComplete="one-time-code"
                    type="text"
                    inputmode="numeric"
                    value={verifyCode}
                    onChange={(e) => {
                      setVerifyCode(e.target.value.replace(/\D/g, ''))
                    }}
                    maxLength={6}
                  />
                  <div>
                    {' '}
                    {isError && (
                      <p
                        style={{
                          color: 'red',
                          position: 'absolute',
                          display: 'flex',
                          justifyContent: 'center',
                          right: '43%',
                          fontSize: '15px',
                        }}
                        className="err_msg"
                      >
                        {isError}
                      </p>
                    )}{' '}
                  </div>
                </div>
                <div className="resend-btn">
                  {!loader && (
                    <a
                      className="StyledLink_inline-underline__jIAnx"
                      data-id="resend-code"
                      href="#"
                      onClick={onSubmitHandler}
                    >
                      Resend Code
                    </a>
                  )}
                  {loader && (
                    <div className="Spinner_loader__xHE9D Spinner_bg-accent-lighter__gxdpU Spinner_loader-accent__AnZPE w-1-1/2 h-1-1/2 absolute"></div>
                  )}
                </div>
              </form>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}
