import './index.css'
import Theme_Button from '../general_components/button'
import { useState, useRef } from 'react'
import SpecialModal from '../all_modals/icontact_from'
import axios from 'axios'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import PhoneInput from 'react-phone-input-2'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import { HashLink } from 'react-router-hash-link'
import { Box } from '@mui/system'
import { MuiTelInput } from 'mui-tel-input'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { setLoginData } from '../../actions/loginAction'
import { useNavigate } from 'react-router-dom'
// import { makeStyles } from '@material-ui/core/styles'

export default function ProfileForm({ pt, pb }) {
  const [success, setSuccess] = useState(false)
  const [phone, setPhone] = useState('')
  const [loader, setLoader] = useState(false)
  const [onload, setOnLoad] = useState(false)

  const loginData = useSelector((state) => state.loginReducer.loginData)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const initialValues = {
    first_name: '',
    last_name: '',
    email: '',
    address: '',
    state: '',
    country: '',
    zip: '',
    role: '',
  }
  const [values, setValues] = useState(initialValues)

  useEffect(() => {
    axios
      .get(`/api/users/${loginData.email}`)
      .then((response) => {
        if (response.data.status === 200) {
          let data = response.data.data
          setValues({
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: data.phone,
            address: data.address,
            state: data.state,
            country: data.country,
            zip: data.zip,
            role: data.role,
          })
          setPhone(data.phone)
          setSuccess(false)
          dispatch(
            setLoginData({
              ...loginData,
              role: data.role,
            }),
          )
          if (data.role === 1) localStorage.setItem('role', data.role)
        }
      })
      .catch((err) => {})
  }, [success])

  const handleinputchange = (e) => {
    setOnLoad(true)
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const onSubmitHandler = (event) => {
    event.preventDefault()
    setOnLoad(false)
    // console.log(phone.replace(/ /g,''))
    // return false
    let data = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: loginData.email,
      phone: phone.replace(/ /g, ''),
      address: values.address,
      state: values.state,
      country: values.country,
      zip: values.zip,
      role: values.role,
    }
    setLoader(true)
    setTimeout(() => {
      axios
        .post('/api/users/update', data)
        .then((response) => {
          if (response.data.status === 200) {
            setSuccess(true)
            setLoader(false)
          } else if (response.data.status === 422) {
            setLoader(false)
          }
        })
        .catch((err) => {})
    }, 1000)
  }

  return (
    <div className="theme_container">
      <div
        style={{ paddingTop: pt, paddingBottom: pb }}
        className="get_started_container profile"
      >
        <div className="w-full max-w-3xl flex flex-col items-center space-y-1">
          <div className="flex flex-col md:flex-row items-center">
            <div className="flex flex-col items-center">
              <span className="rounded-full bg-primary inline-block w-4-7/8 sm:w-7-1/2 p-3/4 sm:p-1"></span>
              <h1 className="type-h2 sm:type-h1 capitalize">
                {values.first_name} {values.last_name}
              </h1>
              <div className="flex items-center mt-1/2">
                <span className="type-subhead mr-1/2">Dreamer since ‘23</span>
              </div>
            </div>
          </div>
        </div>
        <div className="get_started_inputs">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              onSubmitHandler()
            }}
          >
            <Box className="input_row" style={{ textAlign: 'center' }}>
              <TextField
                className="sign_up_text_field"
                style={{ width: '35%' }}
                margin="normal"
                type={'text'}
                required
                label="First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <TextField
                className="sign_up_text_field"
                style={{ width: '35%' }}
                margin="normal"
                type={'text'}
                required
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </Box>
            <Box
              className="input_row"
              style={{
                textAlign: 'center',
                display: 'flex',
                justifyContent: 'center',
                flexWrap: 'wrap',
              }}
            >
              <TextField
                className="sign_up_text_field"
                margin="normal"
                style={{ width: '35%' }}
                type={'email'}
                required
                name="email"
                value={loginData.email}
                disabled={true}
              />
              <MuiTelInput
                style={{ width: '35%', marginTop: 15 }}
                defaultCountry={'US'}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
                value={phone}
                onChange={(e) => {
                  setPhone(e)
                }}
                maxRows={10}
              />
            </Box>
            <Box className="input_row" style={{ textAlign: 'center' }}>
              {' '}
              <TextField
                style={{ width: '35%' }}
                className="sign_up_text_field"
                margin="normal"
                type={'text'}
                label="Address"
                name="address"
                value={values.address}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
              <TextField
                style={{ width: '35%' }}
                className="sign_up_text_field"
                margin="normal"
                type={'text'}
                label="State"
                name="state"
                value={values.state}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </Box>

            <Box className="input_row" style={{ textAlign: 'center' }}>
              <TextField
                style={{ width: '35%' }}
                className="sign_up_text_field"
                margin="normal"
                type={'text'}
                label="Country"
                name="country"
                value={values.country}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />

              <TextField
                style={{ width: '35%' }}
                className="sign_up_text_field"
                margin="normal"
                type={'text'}
                label="Zip"
                name="zip"
                value={values.zip}
                onChange={handleinputchange}
                onKeyPress={(e) => {
                  e.key === 'Enter' && e.preventDefault()
                }}
              />
            </Box>
            <div className="gs_btn">
              <Button
                className="sign_up_submit save-profile"
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmitHandler}
                disabled={onload || loader ? false : true}
              >
                {!loader && <span>Save</span>}
                {loader && (
                  <div className="Spinner_loader__xHE9D Spinner_bg-accent-lighter__gxdpU Spinner_loader-accent__AnZPE w-1-1/2 h-1-1/2 absolute">
                    <span></span>
                  </div>
                )}
              </Button>
            </div>
          </form>
          {/* {success ? <SpecialModal redirect={'sign-in'} /> : null} */}
        </div>
      </div>
    </div>
  )
}
