import Articles from '../components/articles'
import Footer from '../components/footer'
import SecondNavbar from '../components/second_navbar'
import { Helmet } from 'react-helmet-async'
import WishListingCover from '../components/wish_list_cover'
import ListingCover from '../components/listing_cover'
import Get_Started from '../components/contact_forms/get_started'
import SignUpForm from '../components/sign_up/index'
import { useSelector } from 'react-redux'

export default function WishListing() {
  const authed = localStorage.getItem('access_token')
  const loginData = useSelector((state) => state.loginReducer.loginData)

  return (
    <div>
      {/* <Helmet>
        <title>Milenium Haus | Listing </title>
        <meta name="keywords" content="comma seperated value" />
        <meta name="description" content="Milenium Haus" />
      </Helmet> */}
      <SecondNavbar />
      {/* {loginData?.role ?  */}
      <WishListingCover />
      {/* : <SignUpForm />} */}

      {/* <Articles /> */}
      {/* <Get_Started pt={'109px'} pb={'53px'} /> */}
      <Footer />
    </div>
  )
}
