import "./index.css"
import img from "../../assets/images/5.png"
import Theme_Buttom from "../general_components/button"

export default function Rent() {
    return (
        <div className="theme_container">

            <div className="rent_container">
                <div className="rent_image">
                    <img src={img} alt="" />
                </div>
                <div className="rent_content">
                    <h2>Want to own more of your home?  </h2>
                    <div className="rent_image_mobile">
                        <img src={img} alt="" />
                    </div>
                    <div>
                        <p>
                            In simple terms, Milenium Haus SB 9 + AB-1033 can significantly reduce your monthly mortgage payment, save you money over the years, and boost your home equity substantially. It's a smart way to make homeownership more affordable and financially rewarding.
                        </p>
                        <h5>
                            Before Milenium Haus SB 9 + AB-1033 Program:
                        </h5>
                        <p>
                            Imagine you have a typical 30-year mortgage on your home for $500,000 at a 5% interest rate. Your monthly mortgage payment would be around $2,684.11, and over 10 years, you'd spend a total of $322,822.40 on your mortgage payments.<strong> During this time, you'd see an increase in your home equity of ~$83,665.</strong>
                        </p>
                    </div>
                    <div>
                        <h5>
                            After With Milenium Haus SB 9 + AB-1033 Program:
                        </h5>
                        <p>
                            Now, consider the Milenium Haus SB9 Program. You'd have a new mortgage of $150,000, still with a 30-year term and a 5% interest rate. But here's the exciting part: your monthly mortgage payment would be only about $805.23. Over the same 10-year period, you'd spend a total of just $96,627.60 on your mortgage payments.<strong> Even more impressive, your home equity would be estimated to increase by over 5X, ~$446,627.</strong>
                        </p>
                    </div>
                    <div className="ow_btn">
                        <Theme_Buttom to={"/listing"} text={"Become a true Homeowner"} />
                    </div>
                </div>
            </div>
        </div>

    )
}