import './index.css'
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import { useAccordionButton, AccordionContext } from 'react-bootstrap';
import { useContext } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Theme_Buttom from '../general_components/button';
import img from '../../assets/images/plus.png'


function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className='faq_header' onClick={decoratedOnClick}>
            <h3>{children} </h3>
            <button className={isCurrentEventKey ? 'b_active' : ''}> <img src={img} alt="" /> </button>
        </div>

    );
}

function Faq() {
    return (
        <div id='faq' className="faq_container">
            <div className='faq_heading'>
                <h1>Frequently Asked Questions</h1>
            </div>
            <div>
                <Accordion className='faq_wrapper' defaultActiveKey="">
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="0">WHAT IF I WANT TO SELL?</ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>WHAT IF I WANT TO SELL? We’ll help you with rezoning and reparceling by helping you take advantage of SB 9 or AB 1033, some of the most recent legislation that enable us to fast track your ability to sell your new additional unit. </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <ContextAwareToggle eventKey="0">WHAT ABOUT FINANCING? </ContextAwareToggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>We’ll help you qualify for financing with our preferred lenders. First we’ll help you with financing the construction of your Milenium Haus. Then we’ll help you with refinancing, once your Milneium Haus is ready-to-live on your property.  </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                   </Accordion>
                <div className='faq_button'>
                    <Theme_Buttom to={"/ownership"} text={"See All FAQs"} />
                </div>
            </div>
        </div>



    )
}

export default Faq;