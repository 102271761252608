import './index.css'
import img from '../../assets/images/card1.png'
import img2 from '../../assets/images/c1.png'
import img3 from '../../assets/images/c3.png'

import heart from '../../assets/images/heart.png'
// import heart_r from "../../assets/images/red_heart.png"
import pin from '../../assets/images/pd_pin.png'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ImageSlider from '../slider/listing_page_slider'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function ListingCard({ city, searchValue }) {
  const navigator = useNavigate()
  const [propertyList, setPropertyList] = useState([])
  const [propertyArr, setPropertyArr] = useState([])
  const loginData = useSelector((state) => state.loginReducer.loginData)
  const [addPropertyId, setAddPropertyId] = useState('')
  const [addSuccess, setAddSuccess] = useState(true)
  const [deleteId, setDeleteId] = useState('')
  const [deleteSuccess, setDeleteSuccess] = useState(false)

  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  useEffect(() => {
    axios
      .post(`/api/properties/list`, {
        user_id: loginData.user_id,
      })
      .then((response) => {
        if (response.data.status === 200) {
          setAddSuccess(false)
          setDeleteSuccess(false)
          console.log(response.data.data, ' response.data.data')
          let data = response.data.data.map((item) => {
            return {
              ...item,
              configuration: JSON.parse(item.configuration),
              area: JSON.parse(item.area),
              property_image: isJsonString(item.property_image)
                ? JSON.parse(item.property_image)
                : [item.property_image],
            }
          })

          data = data.map((item) => {
            let pricePerSq = item.price / parseInt(item.area.area_of_main_home)
            let aduPrice = 1200 * 350
            let newSize = 1200 + parseInt(item.area.area_of_main_home)
            let newEstimatedValue = Math.ceil(newSize * pricePerSq)
            let mhServicesFee = Math.ceil((newEstimatedValue / 100) * 10)
            let wholePrice = mhServicesFee + aduPrice + parseInt(item.price)
            return {
              ...item,
              wholePrice: wholePrice,
            }
          })
          setPropertyList(
            data.filter((a) => a.city.toLowerCase() === city.toLowerCase()),
          )
          setPropertyArr(data)
        }
      })
  }, [addSuccess, deleteSuccess])

  useEffect(() => {
    setPropertyList(
      propertyArr.filter((a) => a.city.toLowerCase() === city.toLowerCase()),
    )
  }, [city])

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)

  useEffect(() => {
    if (searchValue !== '')
      setPropertyList(
        propertyArr.filter((a) =>
          a.title.toLowerCase().includes(searchValue.toLowerCase()),
        ),
      )
    else {
      setPropertyList(
        propertyArr.filter((a) => a.city.toLowerCase() === city.toLowerCase()),
      )
    }
  }, [searchValue])

  useEffect(() => {
    if (addPropertyId !== '') {
      axios
        .post(`/api/wish-list/save`, {
          user_id: loginData.user_id,
          property_id: addPropertyId,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setAddSuccess(true)
            setAddPropertyId('')
          }
        })
    }
  }, [addPropertyId])

  useEffect(() => {
    if (deleteId !== '') {
      axios
        .post(`/api/wish-list/remove/`, {
          item_id: deleteId,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setDeleteSuccess(true)
            setDeleteId('')
          }
        })
    }
  }, [deleteId])

  return (
    <div className="card_wrapper_main">
      {propertyList.map((item, index) => (
        <div
          key={index}
          className={
            propertyList?.length > 2 ? 'card_wrapper' : 'card_wrapper auto'
          }
          onClick={() => navigator(`/property-detail/?item_id=${item.id}`)}
        >
          <div className="heart_top">
            <button
              data-id="23d2c4d0-d4b1-4508-911a-d6dce79d3960"
              aria-label="unfavorite home"
              style={{ border: 'none' }}
              onClick={(e) => {
                e.stopPropagation()
                item.property[0]?.id === undefined &&
                item.property[0]?.id !== ''
                  ? setAddPropertyId(item.id)
                  : setDeleteId(item.property[0]?.id)
              }}
            >
              {item.property[0]?.id === undefined &&
              item.property[0]?.id !== '' ? (
                <svg
                  className="Icon_icon__DqC9j Icon_icon--md__uoz1f text-gray-800 fill-current w-1-1/2 cursor-pointer"
                  data-id="Heart"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title></title>
                  <path d="M11.94,21.94c-.41,0-.81-.17-1.09-.47L2.76,12.84c-.92-.94-1.53-2.15-1.74-3.48-.21-1.33-.01-2.67,.58-3.88,.44-.9,1.1-1.7,1.89-2.29,.8-.6,1.74-1,2.72-1.17,.99-.16,2-.08,2.95,.23,.95,.31,1.82,.86,2.52,1.57l.25,.26,.25-.26c.7-.72,1.57-1.26,2.52-1.57,.95-.31,1.97-.39,2.95-.23,.98,.16,1.93,.57,2.73,1.17,.8,.6,1.45,1.39,1.89,2.29h0c.59,1.21,.79,2.55,.58,3.88-.21,1.33-.81,2.54-1.75,3.49l-8.08,8.61h0c-.28,.3-.68,.47-1.09,.47ZM7.22,3.45c-.25,0-.51,.02-.76,.06-.74,.12-1.46,.43-2.07,.89-.61,.46-1.11,1.06-1.45,1.75-.45,.93-.61,1.95-.45,2.97,.16,1.02,.62,1.94,1.34,2.67l8.1,8.63h0l8.09-8.62c.72-.74,1.18-1.66,1.35-2.68,.16-1.02,0-2.05-.44-2.97h0c-.34-.69-.84-1.3-1.45-1.75-.61-.46-1.32-.76-2.07-.89-.74-.12-1.52-.06-2.24,.17-.72,.24-1.38,.65-1.92,1.2l-.79,.81c-.28,.29-.79,.29-1.07,0l-.79-.8c-.54-.55-1.2-.96-1.92-1.2-.48-.16-.98-.24-1.48-.24Z"></path>
                </svg>
              ) : (
                <svg
                  className="Icon_icon__DqC9j Icon_icon--md__uoz1f text-gray-800 fill-current w-1-1/2 cursor-pointer"
                  data-id="FilledHeart"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title></title>
                  <path d="M22.28,5.48h0c-.44-.9-1.1-1.7-1.89-2.3-.8-.6-1.74-1.01-2.73-1.17-.98-.16-2-.08-2.95,.23-.95,.31-1.82,.86-2.52,1.57l-.25,.26-.25-.26c-.7-.72-1.57-1.26-2.52-1.57-.95-.31-1.96-.39-2.95-.23-.98,.16-1.92,.57-2.72,1.17-.8,.6-1.45,1.39-1.89,2.29-.59,1.21-.79,2.55-.58,3.88,.21,1.33,.82,2.54,1.74,3.48l8.09,8.62c.28,.3,.68,.47,1.09,.47s.81-.17,1.09-.47h0l8.08-8.62c.93-.95,1.54-2.16,1.75-3.49,.21-1.33,.01-2.67-.58-3.88Z"></path>
                </svg>
              )}
            </button>
          </div>
          <div className="property_image">
            <ImageSlider
              slides={item.property_image}
              sliderData={item.property_image}
            />
            {/* <img
              src={`/${item.property_image}`}
              alt=""
              style={{ height: '250px', witdh: '100%' }}
            /> */}
          </div>
          <div className="card_sub_wrapper">
            <div>
              <h3>{item.title}</h3>
              <p>
                <img src={pin} alt="" /> {item.address}
              </p>
            </div>
            <div className="card_btn_wrapper">
              {' '}
              <span className="buttonTopText">
                {numberFormat(item.wholePrice / 4)}
              </span>{' '}
              <br />
              <span className="buttonBottomText">per share</span>{' '}
            </div>
          </div>
          <div className="card_content_">
            <div className="card_sub_content">
              <span>
                <img src={img2} alt="" />
              </span>
              <span>
                {item.configuration.main_house_bed} BD,{' '}
                {item.configuration.main_house_bath} BA
              </span>
            </div>
            <div className="card_sub_content">
              {item.configuration.adu_bed ? (
                <span>
                  <img src={img2} alt="" />
                </span>
              ) : (
                ''
              )}
              <span>
                {item.configuration.adu_bed
                  ? `${item.configuration.adu_bed} BD, `
                  : ''}
                {item.configuration.adu_bath
                  ? `${item.configuration.adu_bath} BA (ADU)`
                  : ''}
              </span>
              {/* <span>
                <img src={img2} alt="" />
              </span>
              <span>{item.configuration.adu_bed ? `${item.configuration.adu_bed} BD` :''}, {item.configuration.adu_bath} BA (ADU)</span> */}
            </div>
            <div className="card_sub_content">
              <span>
                <img src={img3} alt="" />
              </span>
              <span>1/4 ownership</span>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
