import './index.css'
import * as React from 'react'
import PropTypes from 'prop-types'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import video from '../../../../../assets/images/cover_video.mp4'
import ImageSlider from '../../../../slider'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

export default function ModalTab(props) {
  const [value, setValue] = React.useState(props.tabValue)
  const vidRef = React.useRef(null)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Box
        className="modal_row"
        sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex' }}
      >
        {/* <div className="cross_image" onClick={() => props.setIsOpen(false)}>
          <a className="absolute top-1/2 transform -translate-y-1/2 mt-1/8 ml-1 w-1-1/2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 16.719 16.566"
              className="mr-1/8 h-1-1/2 stroke-current text-white stroke-2"
            >
              <g fill="none">
                <path d="M15.719 8.283H1M7.869 1.415L1 8.283l6.869 6.869"></path>
              </g>
            </svg>
          </a>
        </div> */}
        <div className="modal_column column1 title">
          <div className="hidden lg:flex flex-col text-white overflow-hidden truncate lg:flex-1">
            <div
              className="type-h3 whitespace-no-wrap max-w-full truncate -mb-1/4"
              style={{ fontSize: '1.875rem' }}
            >
              {props.propertyDetails.title}
            </div>
            <div className="type-body whitespace-no-wrap max-w-full truncate">
              {props.propertyDetails.address}
            </div>
          </div>
        </div>
        <div className="modal_column column2 tab-pannel">
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab
              icon={
                <div className="Button___content__MtnK9">
                  <svg
                    className="Icon_icon__DqC9j Icon_icon--sm__4Ul0L Icon_icon--white__jpk-k Button_icon-button__icon__L8eZ-"
                    data-id="Camera"
                    viewBox="0 0 16 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title></title>
                    <path d="M2.42063 13.6066C1.45353 13.6066 0.666504 12.8203 0.666504 11.8541V4.50447C0.666504 3.53829 1.45353 2.75202 2.42063 2.75202H3.96133L5.06184 0.559778C5.14854 0.386531 5.32862 0.273254 5.52204 0.273254H10.4776C10.6711 0.273254 10.8511 0.379868 10.9378 0.559778L12.0383 2.75202H13.579C14.5461 2.75202 15.3332 3.53829 15.3332 4.50447V11.8541C15.3332 12.8203 14.5461 13.6066 13.579 13.6066H2.42063ZM2.42063 3.77817C2.02045 3.77817 1.69364 4.10467 1.69364 4.50447V11.8541C1.69364 12.2539 2.02045 12.5804 2.42063 12.5804H13.5724C13.9726 12.5804 14.2994 12.2539 14.2994 11.8541V4.50447C14.2994 4.10467 13.9726 3.77817 13.5724 3.77817H11.7115C11.5181 3.77817 11.338 3.67156 11.2513 3.49165L10.1508 1.29941H5.82885L4.72835 3.49165C4.64164 3.66489 4.46156 3.77817 4.26814 3.77817H2.42063Z"></path>
                    <path d="M7.9965 10.8879C6.15567 10.8879 4.66165 9.39536 4.66165 7.55628C4.66165 5.7172 6.15567 4.22461 7.9965 4.22461C9.83734 4.22461 11.3314 5.7172 11.3314 7.55628C11.3314 9.39536 9.83734 10.8879 7.9965 10.8879ZM7.9965 5.36404C6.78929 5.36404 5.80217 6.34355 5.80217 7.55628C5.80217 8.76901 6.78262 9.74852 7.9965 9.74852C9.21039 9.74852 10.1908 8.76901 10.1908 7.55628C10.1908 6.34355 9.21039 5.36404 7.9965 5.36404Z"></path>
                  </svg>
                  <span className="text-white whitespace-no-wrap type-body Button_button__label__vnXgq Button_icon-button__label__HdHBw">
                    Photos
                  </span>
                </div>
              }
              {...a11yProps(0)}
            />
            <Tab
              icon={
                <div className="Button___content__MtnK9">
                  <svg
                    className="Icon_icon__DqC9j Icon_icon--sm__4Ul0L Icon_icon--white__jpk-k Button_icon-button__icon__L8eZ-"
                    data-id="Play"
                    fill="none"
                    viewBox="0 0 14 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title></title>
                    <path d="M1.82916 13.645C1.01886 13.645 0.361328 13.0207 0.361328 12.2503V1.74963C0.361328 1.52381 0.421104 1.29135 0.534014 1.0921C0.719983 0.766651 1.0255 0.527548 1.40408 0.414638C1.78266 0.308369 2.17453 0.34822 2.5199 0.520906L12.8678 5.7679C13.1268 5.90073 13.3327 6.09334 13.4655 6.33909C13.8507 7.01655 13.5784 7.86005 12.8678 8.22535L2.5199 13.4723C2.30736 13.5786 2.06826 13.6384 1.82252 13.6384L1.82916 13.645ZM1.82916 1.34448C1.6764 1.34448 1.52364 1.42419 1.45058 1.55702C1.41737 1.6168 1.39744 1.68321 1.39744 1.74963V12.2503C1.39744 12.5425 1.7561 12.7484 2.03505 12.6089L12.3763 7.36192C12.5888 7.25565 12.6685 7.0099 12.5556 6.81065C12.5157 6.73759 12.456 6.68446 12.3763 6.64461L2.03505 1.39098C1.96863 1.35777 1.90222 1.34448 1.82916 1.34448Z"></path>
                  </svg>
                  <span className="text-white whitespace-no-wrap type-body Button_button__label__vnXgq Button_icon-button__label__HdHBw">
                    Videos
                  </span>
                </div>
              }
              {...a11yProps(1)}
            />
            <Tab
              icon={
                <div className="Button___content__MtnK9">
                  <svg
                    className="Icon_icon__DqC9j Icon_icon--sm__4Ul0L Icon_icon--white__jpk-k Button_icon-button__icon__L8eZ-"
                    data-id="ThreeSixty"
                    viewBox="0 0 16 14"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title></title>
                    <path d="M3.39644 5.09337C3.03644 5.09337 2.71644 5.03337 2.43644 4.9067C2.15644 4.78003 1.93644 4.6067 1.76977 4.39337C1.60977 4.17337 1.52311 3.92003 1.51644 3.63337H2.56977C2.56977 3.75337 2.61644 3.86003 2.68977 3.95337C2.76311 4.0467 2.86311 4.11337 2.98311 4.1667C3.10311 4.21337 3.24311 4.24003 3.39644 4.24003C3.55644 4.24003 3.69644 4.21337 3.81644 4.15337C3.93644 4.10003 4.03644 4.02003 4.10311 3.92003C4.16977 3.82003 4.20311 3.7067 4.20311 3.57337C4.20311 3.44003 4.16977 3.3267 4.09644 3.22003C4.02311 3.11337 3.91644 3.04003 3.78311 2.98003C3.64311 2.92003 3.48311 2.89337 3.29644 2.89337H2.83644V2.1267H3.29644C3.45644 2.1267 3.59644 2.10003 3.71644 2.0467C3.83644 1.99337 3.92977 1.91337 4.00311 1.82003C4.06977 1.72003 4.10311 1.6067 4.10311 1.48003C4.10311 1.35337 4.07644 1.2467 4.01644 1.15337C3.95644 1.06003 3.87644 0.9867 3.76977 0.933367C3.66311 0.880033 3.54311 0.853367 3.40311 0.853367C3.26311 0.853367 3.12977 0.880033 3.01644 0.9267C2.89644 0.980034 2.80311 1.0467 2.73644 1.14003C2.66311 1.23337 2.62977 1.34003 2.62311 1.4667H1.62311C1.62311 1.18003 1.70977 0.933367 1.86977 0.713367C2.02977 0.493367 2.24311 0.3267 2.50977 0.200033C2.77644 0.0800333 3.08311 0.0133667 3.42311 0.0133667C3.76311 0.0133667 4.06311 0.0733667 4.31644 0.200033C4.56977 0.3267 4.76977 0.493367 4.91644 0.700033C5.05644 0.913367 5.12977 1.1467 5.12977 1.4067C5.12977 1.6867 5.04311 1.91337 4.86977 2.10003C4.69644 2.2867 4.46977 2.40003 4.19644 2.45337V2.49337C4.55644 2.54003 4.83644 2.6667 5.02977 2.87337C5.22311 3.08003 5.31644 3.33337 5.31644 3.64003C5.31644 3.92003 5.23644 4.17337 5.06977 4.39337C4.90977 4.61337 4.68311 4.7867 4.39644 4.91337C4.10977 5.04003 3.78311 5.10003 3.40977 5.10003L3.39644 5.09337Z"></path>
                    <path d="M7.94977 5.09337C7.69644 5.09337 7.44977 5.0467 7.21644 4.9667C6.98311 4.8867 6.76977 4.7467 6.58311 4.56003C6.39644 4.37337 6.24977 4.12003 6.13644 3.81337C6.02977 3.50003 5.97644 3.12003 5.97644 2.6667C5.97644 2.25337 6.02311 1.88003 6.12311 1.55337C6.21644 1.2267 6.34977 0.9467 6.52977 0.720033C6.70977 0.493367 6.91644 0.320033 7.16311 0.200033C7.40977 0.0800333 7.68311 0.0200334 7.98977 0.0200334C8.32311 0.0200334 8.60977 0.0867 8.86311 0.213367C9.11644 0.340033 9.31644 0.513367 9.47644 0.733367C9.63644 0.953367 9.72311 1.20003 9.75644 1.4667H8.72311C8.68311 1.29337 8.59644 1.16003 8.46977 1.06003C8.34311 0.960033 8.18311 0.913367 7.98977 0.913367C7.66977 0.913367 7.42311 1.05337 7.25644 1.33337C7.08977 1.61337 7.00311 1.99337 7.00311 2.47337H7.03644C7.10977 2.3267 7.20977 2.20003 7.33644 2.10003C7.46311 1.99337 7.60977 1.91337 7.76977 1.86003C7.92977 1.8067 8.10311 1.78003 8.28311 1.78003C8.57644 1.78003 8.83644 1.8467 9.06311 1.9867C9.28977 2.1267 9.46977 2.31337 9.60311 2.55337C9.73644 2.79337 9.80311 3.0667 9.80311 3.38003C9.80311 3.71337 9.72311 4.01337 9.56977 4.27337C9.41644 4.53337 9.19644 4.74003 8.92311 4.8867C8.64311 5.03337 8.32311 5.1067 7.95644 5.1067L7.94977 5.09337ZM7.94977 4.27337C8.10977 4.27337 8.25644 4.23337 8.38311 4.16003C8.50977 4.08003 8.60977 3.98003 8.68311 3.8467C8.75644 3.71337 8.78977 3.5667 8.78977 3.40003C8.78977 3.23337 8.75644 3.0867 8.68311 2.96003C8.60977 2.8267 8.51644 2.7267 8.38977 2.6467C8.26311 2.5667 8.12311 2.53337 7.96311 2.53337C7.84311 2.53337 7.72977 2.55337 7.62977 2.60003C7.52977 2.6467 7.43644 2.7067 7.36311 2.7867C7.28977 2.8667 7.22977 2.96003 7.18311 3.0667C7.13644 3.17337 7.11644 3.2867 7.11644 3.4067C7.11644 3.5667 7.14977 3.71337 7.22977 3.8467C7.30311 3.98003 7.40311 4.0867 7.52977 4.16003C7.65644 4.24003 7.79644 4.28003 7.96311 4.28003L7.94977 4.27337Z"></path>
                    <path d="M12.4831 5.13337C12.0698 5.13337 11.7098 5.0267 11.4098 4.8267C11.1098 4.6267 10.8831 4.3267 10.7231 3.9467C10.5631 3.5667 10.4831 3.10003 10.4831 2.56003C10.4831 2.02003 10.5631 1.56003 10.7231 1.18003C10.8831 0.800033 11.1098 0.513367 11.4098 0.313367C11.7098 0.113367 12.0631 0.0133667 12.4764 0.0133667C12.8898 0.0133667 13.2498 0.113367 13.5431 0.313367C13.8431 0.513367 14.0698 0.800033 14.2298 1.18003C14.3898 1.56003 14.4698 2.02003 14.4698 2.56003C14.4698 3.10003 14.3898 3.5667 14.2298 3.95337C14.0698 4.34003 13.8431 4.63337 13.5431 4.83337C13.2431 5.03337 12.8898 5.14003 12.4764 5.14003L12.4831 5.13337ZM12.4831 4.2667C12.7631 4.2667 12.9898 4.1267 13.1631 3.84003C13.3298 3.55337 13.4164 3.1267 13.4164 2.56003C13.4164 2.1867 13.3764 1.87337 13.3031 1.62003C13.2231 1.37337 13.1164 1.18003 12.9764 1.06003C12.8364 0.940033 12.6698 0.873367 12.4831 0.873367C12.2031 0.873367 11.9764 1.01337 11.8098 1.29337C11.6431 1.57337 11.5564 1.99337 11.5564 2.56003C11.5564 2.94003 11.5964 3.25337 11.6698 3.51337C11.7431 3.7667 11.8564 3.96003 11.9964 4.08003C12.1364 4.2067 12.3031 4.2667 12.4898 4.2667H12.4831Z"></path>
                    <path d="M2.12975 6.27329C1.98309 6.15329 1.66975 6.15329 1.54975 6.27329C1.03642 6.75996 0.663086 7.27329 0.663086 7.95329C0.663086 9.85329 3.03642 11.28 6.44975 11.5333V11.5466L4.94975 13.1466C4.76309 13.3466 4.76975 13.6666 4.96975 13.8533C5.06975 13.9466 5.18975 13.9866 5.30975 13.9866C5.44309 13.9866 5.57642 13.9333 5.67642 13.8266L7.96309 11.3866C7.96309 11.3866 7.98975 11.3466 8.00309 11.3266L8.01642 11.3066C8.01642 11.3066 8.04975 11.2666 8.05642 11.2466V11.2333C8.05642 11.2333 8.07642 11.1733 8.08309 11.14C8.08309 11.1133 8.09642 11.0866 8.09642 11.0533V11.04C8.09642 11.04 8.08309 10.9866 8.07642 10.9533C8.07642 10.92 8.06975 10.8933 8.05642 10.86V10.8466C8.05642 10.8466 8.02309 10.8066 8.00975 10.7866L7.99642 10.7666C7.99642 10.7666 7.97642 10.72 7.95642 10.7066L5.66975 8.26662C5.48309 8.06662 5.16309 8.05329 4.96309 8.24662C4.76309 8.43329 4.74975 8.75329 4.94309 8.95329L6.42309 10.5333C3.40975 10.2866 1.58975 9.07996 1.58975 7.95329C1.58975 7.57996 1.94975 7.20662 2.20309 6.87329C2.38309 6.63996 2.26309 6.39996 2.12309 6.27995L2.12975 6.27329Z"></path>
                    <path d="M14.4697 6.26011C14.3764 6.16011 14.0631 6.08678 13.8097 6.32011C13.5898 6.52678 13.6831 6.81344 13.7698 6.93344C14.0697 7.34011 14.3964 7.59344 14.3964 7.94678C14.3964 8.87344 12.8831 10.0268 9.98975 10.4401C9.73642 10.4734 9.55642 10.7268 9.58975 11.0001C9.62308 11.2734 9.85642 11.4668 10.1164 11.4268C13.2898 10.9734 15.3364 9.60678 15.3364 7.94678C15.3364 7.30011 14.9564 6.80678 14.4764 6.26011H14.4697Z"></path>
                  </svg>
                  <span className="text-white whitespace-no-wrap type-body Button_button__label__vnXgq Button_icon-button__label__HdHBw">
                    3D Tour
                  </span>
                </div>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </div>
        <ul className="modal_column column3 hidden lg:flex items-center flex-1 justify-end">
          {/* <li className="mr-1-3/4">
            <ul className="flex space-x-1 items-center">
              <li>
                <span className="relative flex items-center">
                  <button
                    className="w-1-3/4 sm:w-1-7/8 sm:mb-1/8"
                    type="button"
                    aria-label="Share Listing with others"
                  >
                    <svg
                      className="Icon_icon__DqC9j Icon_icon--md__uoz1f w-1-1/2 text-white fill-current mx-auto fill-current"
                      data-id="Share"
                      viewBox="0 0 18 22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <title></title>
                      <path d="M8.30461 2.48113V10.3647C8.30461 10.7749 8.6448 11.1151 9.05503 11.1151C9.46526 11.1151 9.80545 10.7749 9.80545 10.3647V2.48113L12.2068 4.73215C12.5069 5.01228 12.9872 5.00227 13.2674 4.70214C13.5475 4.402 13.5375 3.92178 13.2374 3.64166L9.57532 0.210096C9.57532 0.210096 9.5353 0.180082 9.52529 0.160073C9.49528 0.130059 9.45525 0.11005 9.42524 0.090041C9.31518 0.0300137 9.19511 0 9.06504 0C8.94497 0 8.8249 0.0300137 8.72485 0.0800364C8.66481 0.11005 8.60478 0.150068 8.55475 0.200091L4.89272 3.63165C4.59255 3.91178 4.57254 4.392 4.8627 4.69213C5.14286 4.99227 5.62312 5.01228 5.92329 4.72215L8.32463 2.47112L8.30461 2.48113Z"></path>
                      <path d="M2.21123 8.53388C2.00111 8.53388 1.81101 8.61392 1.68093 8.73397C1.55086 8.85402 1.49083 8.99409 1.49083 9.13415V19.9191C1.49083 20.0591 1.55086 20.1992 1.68093 20.3192C1.81101 20.4393 2.00111 20.5193 2.21123 20.5193H15.7888C15.9989 20.5193 16.189 20.4393 16.3191 20.3192C16.4491 20.1992 16.5092 20.0591 16.5092 19.9191V9.13415C16.5092 8.99409 16.4491 8.85402 16.3191 8.73397C16.189 8.61392 15.9989 8.53388 15.7888 8.53388H14.328C13.9177 8.53388 13.5775 8.19372 13.5775 7.78354C13.5775 7.37335 13.9177 7.0332 14.328 7.0332H15.7888C16.3591 7.0332 16.9094 7.24329 17.3296 7.62347C17.7499 8.01364 18 8.55389 18 9.12415V19.909C18 20.4893 17.7499 21.0296 17.3296 21.4097C16.9094 21.7999 16.3491 22 15.7888 22H2.21123C1.64091 22 1.09061 21.7899 0.670372 21.4097C0.250139 21.0196 0 20.4793 0 19.909V9.13415C0 8.55389 0.250139 8.01364 0.670372 7.63347C1.09061 7.24329 1.65092 7.0432 2.21123 7.0432H3.67204C4.08227 7.0432 4.42246 7.38336 4.42246 7.79354C4.42246 8.20373 4.08227 8.54388 3.67204 8.54388H2.21123V8.53388Z"></path>
                    </svg>
                  </button>
                </span>
              </li>
            </ul>
          </li>
          <li>
            <span className="relative flex items-center">
              <button
                data-id="b54763ef-808c-415b-9bf0-6c275ee30a4a"
                aria-label="favorite home"
              >
                <svg
                  className="Icon_icon__DqC9j Icon_icon--md__uoz1f w-1-1/2 text-white fill-current"
                  data-id="Heart"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title></title>
                  <path d="M11.94,21.94c-.41,0-.81-.17-1.09-.47L2.76,12.84c-.92-.94-1.53-2.15-1.74-3.48-.21-1.33-.01-2.67,.58-3.88,.44-.9,1.1-1.7,1.89-2.29,.8-.6,1.74-1,2.72-1.17,.99-.16,2-.08,2.95,.23,.95,.31,1.82,.86,2.52,1.57l.25,.26,.25-.26c.7-.72,1.57-1.26,2.52-1.57,.95-.31,1.97-.39,2.95-.23,.98,.16,1.93,.57,2.73,1.17,.8,.6,1.45,1.39,1.89,2.29h0c.59,1.21,.79,2.55,.58,3.88-.21,1.33-.81,2.54-1.75,3.49l-8.08,8.61h0c-.28,.3-.68,.47-1.09,.47ZM7.22,3.45c-.25,0-.51,.02-.76,.06-.74,.12-1.46,.43-2.07,.89-.61,.46-1.11,1.06-1.45,1.75-.45,.93-.61,1.95-.45,2.97,.16,1.02,.62,1.94,1.34,2.67l8.1,8.63h0l8.09-8.62c.72-.74,1.18-1.66,1.35-2.68,.16-1.02,0-2.05-.44-2.97h0c-.34-.69-.84-1.3-1.45-1.75-.61-.46-1.32-.76-2.07-.89-.74-.12-1.52-.06-2.24,.17-.72,.24-1.38,.65-1.92,1.2l-.79,.81c-.28,.29-.79,.29-1.07,0l-.79-.8c-.54-.55-1.2-.96-1.92-1.2-.48-.16-.98-.24-1.48-.24Z"></path>
                </svg>
              </button>
            </span>
          </li
          <span className="h-2-3/4 w-0 border-transparent border-1 border-l-gray-700 sm:h-0 sm:w-2-3/4 sm:border-l-transparent sm:border-b-gray-700 lg:h-2 lg:w-0 lg:border-l-gray-700 lg:border-b-transparent ml-1-3/4"></span>
          */}
          <li className="m-1-1/8 -mr-1/2">
            <button
              aria-label="Close Media Hub"
              className="close-button Button_button__2JA4L Button_icon-button__92EF8 Button_icon-button--white__1MAfl"
              data-id="Close Media Hub"
              type="button"
              onClick={() => props.setIsOpen(false)}
            >
              <div className="Button___content__MtnK9">
                <svg
                  className="Icon_icon__DqC9j Icon_icon--sm__4Ul0L Icon_icon--white__jpk-k Button_icon-button__icon__L8eZ-"
                  data-id="Close"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Close Media Hub</title>
                  <g>
                    <path d="M21.66,20.6L13.06,12,21.66,3.4c.29-.29,.29-.77,0-1.06s-.77-.29-1.06,0L12,10.94,3.4,2.34c-.29-.29-.77-.29-1.06,0s-.29,.77,0,1.06L10.94,12,2.34,20.6c-.29,.29-.29,.77,0,1.06,.15,.15,.34,.22,.53,.22s.38-.07,.53-.22L12,13.06l8.6,8.6c.15,.15,.34,.22,.53,.22s.38-.07,.53-.22c.29-.29,.29-.77,0-1.06Z"></path>
                  </g>
                </svg>
              </div>
            </button>
          </li>
        </ul>
      </Box>
      <TabPanel value={value} index={0}>
        <ImageSlider slides={props.sliderData} sliderData={props.sliderData} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <iframe
          src={
            props.propertyDetails.video_url !== null
              ? `/${props.propertyDetails.video_url}`
              : video
          }
          style={{
            width: '900px',
            height: '600px',
          }}
          allowFullScreen={true}
          // allow="autoplay"
        ></iframe>
        {/* <video
          ref={vidRef}
          playsInline
          controls={true}
          autoPlay={false}
          style={{
            width: '60%',
            // height: '80%',
          }}
        >
          <source
            src={
              props.propertyDetails.video_url !== null
                ? `/${props.propertyDetails.video_url}`
                : video
            }
            type="video/mp4"
          />
        </video> */}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <iframe
          // src={
          //   'https://my.matterport.com/show/?m=qUe81UayJNj&ts=0&hl=0&guides=0&lp=1&play=1'
          // }
          src={`https://my.matterport.com/show/?m=${props.propertyDetails?.iframe_url}&ts=0&hl=0&guides=0&lp=1&play=1`}
          allowfullscreen=""
          width="100vh"
          height="74vh"
        ></iframe>
      </TabPanel>
    </Box>
  )
}
