import './index.css'
import { useState } from 'react'
import SpecialModal from '../../all_modals/icontact_from'
import axios from 'axios'

export default function Chance() {
  const [isError, setIsError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [mailInvalid, setMailInvalid] = useState(false)

  const initialValues = {
    email: '',
    customer_type: 'TIC Co-ownership Buyer',
  }
  const [values, setValues] = useState(initialValues)

  const defaultState = {
    email: false,
  }

  const [touched, setTouched] = useState(defaultState)

  const handleFocus = (e) => {
    setTouched({
      ...touched,
      email: true,
    })
  }

  const handleinputchange = (e) => {
    const { name, value } = e.target
    setValues({ ...values, [name]: value })
  }

  const enteredEmailIsValid = values.email.trim() !== ''

  const emailInputIsInvalid = touched.email && !enteredEmailIsValid

  const onSubmitHandler = (event) => {
    event.preventDefault()
    setTouched({
      email: true,
    })

    if (!enteredEmailIsValid) {
      return
    }

    if (
      values.email.trim() !== '' &&
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      setMailInvalid(false)
    } else {
      setMailInvalid(true)
      return
    }

    let data = {
      email: values.email,
      customer_type: values.customer_type,
    }

    axios
      .post('/api/contact/contact-us', data)
      .then((response) => {
        if (response.data.status === 200) {
          setSuccess(true)
          setValues(initialValues)
          setTouched(defaultState)
          // setChecked(false)
          // setPhone('')
        } else if (response.data.status === 422) {
          setIsError(response.data.message)
          // setChecked(false)
          // setValues(initialValues)
          setTouched(defaultState)
        }
      })
      .catch((err) => {})
  }

  return (
    <div className="theme_container">
      <div className="Chance_container">
        <div className="chance_content">
          <h1>Don't miss your chance</h1>
          <p>
            We make it simple to add additional housing to your property. We’ll help take care of all the legal paperwork, mortgages, including refinancing, to make living with Milenium Haus easy and enjoyable. 
          </p>
        </div>
        <form onSubmit={onSubmitHandler}>
          <div className="inputs_container">
            <div className="email_input">
              {isError ? (
                <p className="error_msg">Please fill all required fields.</p>
              ) : null}
              <input
                // type="email"
                // required
                // // ref={refEmail}
                // value={email}
                // onChange={(e) => setEmail(e.target.value)}
                // placeholder="Email*"
                type="email"
                name={'email'}
                onChange={handleinputchange}
                value={values.email}
                pattern="[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,64}"
                onBlur={handleFocus}
                placeholder="Email*"
                required
              />
              {emailInputIsInvalid && (
                <p className="e-message">Please enter a Email</p>
              )}
              {mailInvalid && (
                <p className="e-message">Please enter a valid email</p>
              )}
            </div>
            <div className="radio_button">
              <p>I am a</p>
              <div className="radio_wrapper">
                <span className="dd_conte">
                  <input
                    className=""
                    type="radio"
                    name="customer_type"
                    id="tic"
                    value="TIC Co-ownership Buyer"
                    onChange={handleinputchange}
                    checked={
                      values.customer_type === 'TIC Co-ownership Buyer'
                        ? true
                        : false
                    }
                    required
                    defaultChecked
                  />
                  <label className="" htmlFor="tic">
                    First time home buyer
                  </label>
                </span>
                <span className="dd_conte">
                  <input
                    className=""
                    type="radio"
                    name="customer_type"
                    id="Investor"
                    value="Investor"
                    onChange={handleinputchange}
                    checked={values.customer_type === 'Investor' ? true : false}
                    required
                  />
                  <label className="" htmlFor="Investor">
                    Owner
                  </label>
                </span>
                <span className="dd_conte">
                  <input
                    className=""
                    type="radio"
                    name="customer_type"
                    id="Agent"
                    value="Agent/Broker"
                    onChange={handleinputchange}
                    checked={
                      values.customer_type === 'Agent/Broker' ? true : false
                    }
                    required
                  />
                  <label className="" htmlFor="Agent">
                    Agent/Broker
                  </label>
                </span>
              </div>
            </div>

            <div className="agree_wrapper">
              <input
                type="checkbox"
                readOnly
                defaultChecked
                name="agree"
                id="agree"
              />{' '}
              <label htmlFor="">
                {' '}
                I give Milenium Haus permission to contact me & agree to the{' '}
                <a href="">Term and Conditions.</a>
              </label>
            </div>
            <div className="gs_btn">
              <button onClick={onSubmitHandler}>Learn More</button>
            </div>
            {success ? <SpecialModal redirect={'listing'} /> : null}
          </div>
        </form>
      </div>
    </div>
  )
}
