import './index.css'
import img from '../../assets/images/2.png'
import img1 from '../../assets/images/11.png'
import { HiMenu } from 'react-icons/hi'
import { HashLink } from 'react-router-hash-link'
import { useState, useEffect } from 'react'
import { IoClose } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { setLoginData } from './../../actions/loginAction'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import SideBar from '../sidebar'

export default function Navbar() {
  const [click, setClick] = useState(true)
  const handleClick = () => setClick(!click)
  const loginData = useSelector((state) => state.loginReducer.loginData)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    window.addEventListener('scroll', isSticky)
    return () => {
      window.removeEventListener('scroll', isSticky)
    }
  })

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const scrollTop = window.scrollY
    const header = document.querySelector('.navbar_container')

    scrollTop >= 98
      ? header.classList.add('shrink_navbar')
      : header.classList.remove('shrink_navbar')
  }

  return (
    <div className="navbar_container_wrapper">
      <div className="navbar_container">
        <div className="navbar_logo">
          <HashLink smooth to={'/'}>
            <img src={img} alt="" />
          </HashLink>
        </div>
        <div className="navbar_menu_items">
          <span>
            <HashLink smooth to={'/learn'}>
              Learn
            </HashLink>
          </span>
          <span>
            <HashLink smooth to={'/listing'}>
              Listings
            </HashLink>
          </span>
          <span>
            <HashLink smooth to={'/access'}>
              My Milenium haus
            </HashLink>
          </span>
          <span>
            <HashLink smooth to={'/contact'}>
              contact
            </HashLink>
          </span>
          {loginData?.role === 1 && (
            <span>
              <HashLink smooth to={'/add-property'}>
                Add Property
              </HashLink>
            </span>
          )}
          {loginData?.role === 1 && (
            <span>
              <HashLink smooth to={'/manage-property'}>
                Manage Property
              </HashLink>
            </span>
          )}
          {/* {!loginData?.access_token && (
            <span>
              <HashLink smooth to={'/sign-up'}>
                Sign Up
              </HashLink>
            </span>
          )}
          {loginData?.access_token && (
            <span>
              <button
                onClick={() => {
                  navigate('/')
                  window.localStorage.removeItem('access_token')
                  dispatch(setLoginData([]))
                }}
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: '#b8d6d9',
                  fontWeight: '600',
                  fontSize: '15px',
                  lineHeight: '18px',
                  textAlign: 'right',
                  textTransform: 'uppercase',
                  color: '#FFFFFF',
                  // marginRight: '76px',
                  cursor: 'pointer',
                }}
              >
                Sign Out
              </button>
            </span>
          )} */}
          <span className="wish-list">
            <HashLink smooth to={'/wish-list'}>
              <svg
                viewBox="0 0 31 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="text-inherit fill-current h-1-3/8 sm:h-1-1/4 align-middle"
                role="img"
                aria-label="Favorites"
              >
                <path d="M15.42 27.253c-.547 0-1.08-.226-1.453-.626L3.18 15.12a8.514 8.514 0 01-2.32-4.64 8.658 8.658 0 01.773-5.173 8.502 8.502 0 012.52-3.054A8.476 8.476 0 017.78.693 8.368 8.368 0 0111.713 1a8.423 8.423 0 013.36 2.093l.334.347.333-.347A8.279 8.279 0 0119.1 1a8.38 8.38 0 013.933-.307 8.367 8.367 0 013.64 1.56 8.479 8.479 0 012.52 3.054 8.658 8.658 0 01.774 5.173 8.438 8.438 0 01-2.334 4.653L16.86 26.613c-.373.4-.907.627-1.453.627l.013.013zM9.127 2.6c-.334 0-.68.027-1.014.08a6.295 6.295 0 00-2.76 1.187A6.561 6.561 0 003.42 6.2c-.6 1.24-.813 2.6-.6 3.96a6.448 6.448 0 001.787 3.56l10.8 11.507 10.786-11.494a6.639 6.639 0 001.8-3.573 6.74 6.74 0 00-.586-3.96 6.448 6.448 0 00-4.694-3.52 6.487 6.487 0 00-2.986.227c-.96.32-1.84.866-2.56 1.6l-1.054 1.08c-.373.386-1.053.386-1.426 0L13.633 4.52a6.449 6.449 0 00-2.56-1.6A6.24 6.24 0 009.1 2.6h.027z"></path>
              </svg>
            </HashLink>
          </span>
          <span className="menu_icon_desktop">
            <SideBar />
          </span>
        </div>
      </div>

      {/* Mobile Navbar Start */}
      <div className="m_navbar">
        <div className="m_navbar_logo">
          <HashLink smooth to={'/'}>
            <img src={img} alt="" />
          </HashLink>
        </div>
        <div>
          <span onClick={handleClick}>
            {/* <HiMenu /> */}
            <SideBar />
          </span>
        </div>
      </div>
      <div className={click ? 'mobile-menu  menu-open' : 'mobile-menu'}>
        <div className="logo order-md-1">
          <div className="fix-icon text-dark" onClick={handleClick}>
            <IoClose />
          </div>
        </div>

        <div className="navbar-nav">
          <li className="nav-item">
            <HashLink
              smooth
              to={'/learn'}
              className="nav-link"
              onClick={handleClick}
            >
              Learn
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              smooth
              to={'/listing'}
              className="nav-link"
              onClick={handleClick}
            >
              Listings
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              smooth
              to={'/access'}
              className="nav-link"
              onClick={handleClick}
            >
              My   Milenium haus
            </HashLink>
          </li>
          <li className="nav-item">
            <HashLink
              smooth
              to={'/contact'}
              className="nav-link"
              onClick={handleClick}
            >
              contact
            </HashLink>
          </li>
        </div>
      </div>
    </div>
  )
}
