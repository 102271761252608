import "./index.css"
import access_page from '../../assets/images/access_page.png'
import image1 from '../../assets/images/worker-drawing-blueprint.jpg'
import image2 from '../../assets/images/construction-silhouette.jpg'
import image4 from '../../assets/images/modern-country-houses-construction.jpg'
import image5 from '../../assets/images/3d-rendering-loft-luxury-living-room-with-shelf-near-dining-table.jpg'
import image6 from '../../assets/images/how.png'
import image7 from '../../assets/images/construction-site.jpg'
import image8 from '../../assets/images/trucks-highway-mountain-sunset.jpg'
import image9 from '../../assets/images/construction-silhouette.jpg'
import image10 from '../../assets/images/full-shot-man-woman-standing-ladder.jpg'
import image11 from '../../assets/images/woman-suggesting-changes-plan.jpg'

export default function AccessCover() {
    return (

        <div className="access_container_bg theme_container p-relative">
            <div className="access_container">
                <div className="Access_sub_container">
                    <h2>Our Process</h2>
                    <p>
                        Milenium Haus models require a concrete pier foundation and depending on your use, connections to utilities (electricity, water, and sewer). This means there are zoning, permitting, and other logistical site-specific requirements that may affect your placement permissions. This might include restrictions imposed by a Homeowners Association where applicable. These requirements can only be confirmed by professional analysis. We are working on resources and establishing partners to oversee this process for you, but until they are in place we have outlined the following steps for you to execute prior to reserving a model.
                    </p>
                </div>

            </div>

            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>01</span> Zoning
                    </h2>
                    <p>
                        We will contact your city’s or county’s Planning and Zoning/Development Department for a Parcel Report to verify whether a Milenium Haus model is allowed to be placed on your property. Note: There may be different requirements and restrictions for an ADU versus a primary residence. There may also be some additional restrictions that apply such as: setback, access (driveways), parking, aesthetics, structure type, etc. If there is a Homeowners Association (HOA) with its own rules regarding properties, or if you are in a historical district, we will check on these as well.
                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image1} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>02</span> Reserve your spot in the queue
                    </h2>
                    <p>
                        After you’ve confirmed that your property is eligible for a Milenium Haus, now is the time for you to reserve your spot in the queue in our Production Calendar. Just make your reservation with a non-refundable $1,000 fee to get your architectural plans. Your Production Start Date will be MINIMALLY 120 days from this point. We will keep you in the loop.
                    </p>
                    <p>
                        Should you wish to cancel your reservation you may submit a request to us. Note, however, the reservation fee is nonrefundable under any circumstances.
                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image2} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>03</span> Financing (optional)
                    </h2>
                    <p>
                        If you wish to finance your Milenium Haus, now would be the time to start. Being partnered with Vanguard Properties, we will connect you with a dedicated loan officer who can help you obtain a mortgage for your new Milenium Haus.
                    </p>
                </div>
                <div className="image">

                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>04</span> Zoning clearance package
                    </h2>
                    <p>
                        <p>If you wish to finance your Milenium Haus, now would be the time to start. Being partnered wWe request a $10,000 Build Deposit before we can start building your Milenium Haus. This deposit can be divided into two payments. The first Build Deposit payment of $5,000 is due when you wish to begin the permitting process. The permitting process is often the longest part of the entire process so we suggest starting immediately.</p>
                        <p>Upon receipt of your $5,000 payment, we will provide you with the Zoning Clearance Package, a full set of signed and sealed construction documents for the modular portion of the project including architectural, structural, mechanical, electrical, plumbing, foundation and installation details:</p>
                        <ul>
                            <li>Floor plans</li>
                            <li> Elevations</li>
                            <li>Building sections</li>
                            <li>Wall sections and details</li>
                            <li>Foundation design and instructions for a standard typical installation</li>
                            <li>General Installation Instructions</li>
                        </ul>
                        <p>At minimum you will need to make this payment 120 days prior to your build start date.</p>
                        ith Vanguard Properties, we will connect you with a dedicated loan officer who can help you obtain a mortgage for your new Milenium Haus.

                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image4} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>05</span> Permitting
                    </h2>
                    <p>
                        If you wish to finance your Milenium Haus, now would be the time to start. Being partnered with Vanguard Properties, we will connect youNow we will obtain building permits from your local governing body and arrange for site inspections. We will submit the details of the structure set out in step 4, such that the governing body understands the building’s strength and durability to withstand weather and seismic events. Equipped with our Zoning Clearance Package, our civil engineer will confirm with permitting authorities that zoning setbacks, soil composition, foundation requirements, and utility connections all meet local code requirements. From the time of initial Build Deposit, we will have 120 days to obtain permits and approved site plan in order to keep your spot on our Production Calendar.
                        with a dedicated loan officer who can help you obtain a mortgage for your new Milenium Haus.
                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image5} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>06</span> Production start date
                    </h2>
                    <p>
                        <p>Contingent upon receipt of your approved site plans and permits, we will email you 30 days before your Build Start Date to collect the second portion of your deposit. This $5,000 is due within 2 weeks of Build Start Date. Upon receipt of your second Deposit, your order will enter into Pre-Production.</p>
                        Note that 14 days from your Build Start Date, you will no longer be able to make any changes to your order. We will email you to notify you that your second Production Deposit has been received. We estimate 2-4 weeks from Production Start Date until your unit is ready to ship.

                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image6} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>07</span> Site Preparation
                    </h2>
                    <p>
                        <p>If you wish to finance your Milenium Haus, now would be the time to start. Being partnered with Vanguard Properties, we will connect youNowAs soon as we have your permits we will schedule the building of your foundation and utility access. We use pre-vetted contractor(s) to prepare your site and to install your Milenium Haus.</p>

                        <b>Foundation</b><br></br>
                        <p>Your Milenium Haus will need to be secured to a solid foundation. This foundation is built by a local contractor in compliance with Milenium Haus specifications and local government requirements. The foundation will need to already be in place, inspected, and approved prior to your Milenium Haus arriving on site.</p>

                        <b>Utility Access</b><br></br>
                        <p>Sewer, water, and electrical connections need to be installed or extended to the foundation so that your Milenium Haus can be hooked up to these services once placed.</p>
                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image7} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <div>
                        <h2>
                            <span>08</span> Shipping
                        </h2>
                        <p>
                            After your Milenium Haus production has been completed at our factory, we arrange for shipping to your property. Upon confirmation that your site is ready, we manage the on-site installation, keeping you in the loop every step of the way.
                        </p>
                    </div>
                    <div>
                        <h2>
                            <span>09</span> Final Payment
                        </h2>
                        <p>
                            The balance of your payment is due when your Milenium Haus arrives on your site.
                        </p>
                    </div>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image8} alt="" />
                </div>
            </div>

            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>10</span> Site installation
                        Placement
                    </h2>
                    <p>
                        <p> After the permitting and inspection processes for the foundation and utilities are completed and approved and the home components have been shipped by truck to the site, your Milenium Haus can now be placed and assembled. Installation can be up to a 7-day process.</p>
                        <p>Our installation crew will remove the unit from the truck, secure the modules to the foundation, mate and seal the components together, install the porches, gabion walls and the robotic furniture.</p>

                        <b>Utility Hookup</b><br></br>
                        <p>Sewer, water, electrical, and hookups will be performed by a licensed contractor and are subject to inspection and approval by local utilities or governmental authorities.</p>

                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image10} alt="" />
                </div>
            </div>
            <div className="access_container1">
                <div className="text_content">
                    <h2>
                        <span>11</span> Certificate of Occupancy
                    </h2>
                    <p>
                        <p>Once everything is in place and inspected, you will receive a certificate of occupancy for the new Milenium Haus building on your property.</p>

                    </p>
                </div>
                <div className="image">
                    <img class="make_image_sticky" src={image11} alt="" />
                </div>
            </div>
        </div>

    )
}