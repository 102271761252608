import './index.css'
// import cover from "../../assets/images/listing_bg.png"
import cover from '../../assets/images/listing_bg1.png'
import search from '../../assets/images/search.png'
import search2 from '../../assets/images/w_search.png'
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import gps from '../../assets/images/gps.png'
import Dropdown from 'react-bootstrap/Dropdown'
import img1 from '../../assets/images/card.png'
import ListingCard from '../listing_card'
import { Typeahead } from 'react-bootstrap-typeahead' // ES2015
import 'react-bootstrap-typeahead/css/Typeahead.css'

import img from '../../assets/images/card1.png'
import img2 from '../../assets/images/c1.png'
import img3 from '../../assets/images/c3.png'

import heart from '../../assets/images/heart.png'
// import heart_r from "../../assets/images/red_heart.png"
import pin from '../../assets/images/pd_pin.png'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ImageSlider from '../slider/listing_page_slider'
import { useSelector } from 'react-redux'
import axios from 'axios'

export default function WishListingCover() {
  const navigator = useNavigate()
  const [propertyList, setPropertyList] = useState([])
  const loginData = useSelector((state) => state.loginReducer.loginData)
  const [deleteId, setDeleteId] = useState('')
  const [deleteSuccess, setDeleteSuccess] = useState(false)

  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }

  const numberFormat = (value) =>
    new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    }).format(value)

  useEffect(() => {
    axios.get(`/api/wish-list/list/${loginData.user_id}`).then((response) => {
      if (response.data.status === 200) {
        setDeleteSuccess(false)
        let data = response.data.data.map((item) => {
          return {
            ...item.property,
            wish_item_id: item.id,
          }
        })

        data = data.map((item) => {
          return {
            ...item,
            configuration: JSON.parse(item.configuration),
            area: JSON.parse(item.area),
            property_image: isJsonString(item.property_image)
              ? JSON.parse(item.property_image)
              : [item.property_image],
          }
        })

        data = data.map((item) => {
          let pricePerSq = item.price / parseInt(item.area.area_of_main_home)
          let aduPrice = 1200 * 350
          let newSize = 1200 + parseInt(item.area.area_of_main_home)
          let newEstimatedValue = Math.ceil(newSize * pricePerSq)
          let mhServicesFee = Math.ceil((newEstimatedValue / 100) * 10)
          let wholePrice = mhServicesFee + aduPrice + parseInt(item.price)
          return {
            ...item,
            wholePrice: wholePrice,
          }
        })
        setPropertyList(data)
      }
    })
  }, [deleteSuccess])

  useEffect(() => {
    if (deleteId !== '') {
      axios
        .post(`/api/wish-list/remove/`, {
          item_id: deleteId,
        })
        .then((response) => {
          if (response.data.status === 200) {
            setDeleteSuccess(true)
            setDeleteId('')
          }
        })
    }
  }, [deleteId])

  return (
    <div className="listing_container">
      <div className="card_container theme_container">
        <div className="card_heading wishlist">
          <div>
            <h1>Wish List</h1>
          </div>
        </div>
        <div className="card_wrapper_main">
          {propertyList.map((item, index) => (
            <div
              key={index}
              className={
                propertyList?.length > 2 ? 'card_wrapper' : 'card_wrapper auto'
              }
              onClick={() =>
                navigator(`/property-detail/?item_id=${item.id}`, {
                  replace: true,
                })
              }
            >
              {/* {console.log(item, 'item')} */}
              <div className="heart_top">
                <button
                  data-id="23d2c4d0-d4b1-4508-911a-d6dce79d3960"
                  aria-label="unfavorite home"
                  style={{ border: 'none' }}
                  onClick={(e) => {
                    setDeleteId(item.wish_item_id)
                    e.stopPropagation()
                  }}
                >
                  <svg
                    className="Icon_icon__DqC9j Icon_icon--md__uoz1f text-gray-800 fill-current w-1-1/2 cursor-pointer"
                    data-id="FilledHeart"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title></title>
                    <path d="M22.28,5.48h0c-.44-.9-1.1-1.7-1.89-2.3-.8-.6-1.74-1.01-2.73-1.17-.98-.16-2-.08-2.95,.23-.95,.31-1.82,.86-2.52,1.57l-.25,.26-.25-.26c-.7-.72-1.57-1.26-2.52-1.57-.95-.31-1.96-.39-2.95-.23-.98,.16-1.92,.57-2.72,1.17-.8,.6-1.45,1.39-1.89,2.29-.59,1.21-.79,2.55-.58,3.88,.21,1.33,.82,2.54,1.74,3.48l8.09,8.62c.28,.3,.68,.47,1.09,.47s.81-.17,1.09-.47h0l8.08-8.62c.93-.95,1.54-2.16,1.75-3.49,.21-1.33,.01-2.67-.58-3.88Z"></path>
                  </svg>
                </button>
              </div>
              <div className="property_image">
                <ImageSlider
                  slides={item.property_image}
                  sliderData={item.property_image}
                />
              </div>
              <div className="card_sub_wrapper">
                <div>
                  <h3>{item.title}</h3>
                  <p>
                    <img src={pin} alt="" /> {item.address}
                  </p>
                </div>
                <div className="card_btn_wrapper">
                  {' '}
                  <span className="buttonTopText">
                    {numberFormat(item.wholePrice / 4)}
                  </span>{' '}
                  <br />
                  <span className="buttonBottomText">per share</span>{' '}
                </div>
              </div>
              <div className="card_content_">
                <div className="card_sub_content">
                  <span>
                    <img src={img2} alt="" />
                  </span>
                  <span>
                    {item.configuration.main_house_bed} BD,{' '}
                    {item.configuration.main_house_bath} BA
                  </span>
                </div>
                <div className="card_sub_content">
                  {item.configuration.adu_bed ? (
                    <span>
                      <img src={img2} alt="" />
                    </span>
                  ) : (
                    ''
                  )}
                  <span>
                    {item.configuration.adu_bed
                      ? `${item.configuration.adu_bed} BD, `
                      : ''}
                    {item.configuration.adu_bath
                      ? `${item.configuration.adu_bath} BA (ADU)`
                      : ''}
                  </span>
                </div>
                <div className="card_sub_content">
                  <span>
                    <img src={img3} alt="" />
                  </span>
                  <span>1/4 ownership</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
