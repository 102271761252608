import './index.css'
// import img from "../../assets/images/1.png"
import img from '../../assets/images/cover_video.mp4'
import Theme_Buttom from '../general_components/button'

export default function Cover() {
  return (
    <div className="cover_container" id="cover_container">
      <div className="cover_image">
        <video playsInline loop autoPlay="true" muted>
          <source src={img} type="video/mp4" />
        </video>
      </div>
      <div className="cover_content">
        <div className="cover_content_sub">
          <h2>How Milenium Haus Works</h2>
          <h1>Use Your Home to Become Debt Free.</h1>
          <p>
            We make minimizing or eliminating your mortgage easy, in high-value real estate markets.{' '}
          </p>
          <Theme_Buttom to={'/listing'} text={'Explore Now'} />
        </div>
      </div>
    </div>
  )
}
