import './index.css'
import arr from '../../../../assets/images/arrow.png'
import AmenitiesModal from '../../../all_modals/amenities_modal'
import { useEffect, useState, useRef } from 'react'
import Modal from 'react-modal'

export default function AboutProperty({ propertyDetails }) {
  const [ameneties, setAmenities] = useState([])

  useEffect(() => {
    setAmenities(propertyDetails?.amenities?.split(','))
  }, [propertyDetails])

  return (
    <>
      {' '}
      <div className="pd_about_container">
        <div className="pd_about_content">
          <h1>About this property</h1>
          <p>{propertyDetails.description} </p>
        </div>
        <div className="sub_about_heading">
          <h3>Top amenities</h3>
        </div>
        <div className="pd_about_sub">
          {ameneties.map((item, index) => (
            <div className="flex_wrap" key={index}>
              <span>
                <img src={arr} alt="" />
              </span>
              <span> {item}</span>
            </div>
          ))}
        </div>
        <div className="about_pd_button">
          <AmenitiesModal ameneties={propertyDetails?.amenities?.split(',')} />
        </div>
        <div className="tour_heading">
          <h1>Virtual 3D tour</h1>
        </div>
      </div>
    </>
  )
}
