import "./index.css"
import img1 from "../../assets/images/shop.png"
import img2 from "../../assets/images/own.png"
import img3 from "../../assets/images/enjoy.png"
import Theme_Buttom from "../general_components/button"


export default function HomeOwner({ display_button }) {
    return (
        <div className="theme_container">
            <div className="homeowner_container">
                <div className="homeowner_heading">
                    <h1>Become a Homeowner with Milenium Haus</h1>
                </div>
                <div className="homeowner_content">
                    <div className="homeowner_sub_content">
                        <div><span><img src={img1} alt="" /></span> <h1>Shop</h1> </div>
                        {/* <p style={{fontSize:"16px", fontWeight:600}}>(DISCOVER/FIND/EXPLORE) leading with rewards/our risk free qualification</p> */}
                        <p>Start your journey with Milenium Haus today and we’ll help you qualify for  property refinancing, and provide a risk-free end-to-end experience, that eliminates or minimizes your mortgage in record time.  </p>
                    </div>
                    <div className="homeowner_sub_content">
                        <div> <span><img src={img2} alt="" /></span><h1>Own</h1> </div>
                        {/* <p style={{ fontSize: "16px", fontWeight: 600 }}>(LLC, TIC, operating agreement) (REWARD MINIMIZATION OF DOWNSIDE INVERTED POSITION)</p> */}
                        <p>Banks own 88% of residential real estate. We’re changing that. We help you become financially free by adding an ADU (accessory dwelling unit) to your property and handle everything for you, so you end up debt free or with a minimal mortgage to claim against your taxes.</p>
                    </div>
                    <div className="homeowner_sub_content">
                        <div> <span><img src={img3} alt="" /></span> <h1>Enjoy</h1> </div>
                        {/* <p style={{ fontSize: "16px", fontWeight: 600 }}>(MORE REWARD)</p> */}
                        <p>We do all the paperwork, and fast track the process, leveraging legal expertise to take advantage of newly-passed laws, to fast track your approval process. You simply let us lead the way, and enjoy your financial freedom. </p>
                    </div>
                </div>
                {display_button ? <div className="ho_button_wrapper">
                    <Theme_Buttom to={"/learn"} text={'Get Started'} />
                </div> :
                    null
                }

            </div>
        </div>

    )
}