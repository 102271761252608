import "./index.css"
import img from "../../assets/images/learn_cover.png"


export default function LearnCover() {
    return (
        <div className="learn_container">
            <div className="learn_content">
                <h3>How Milenium Haus Works</h3>
                <h1>Use Your Home to Become Debt Free.</h1>
                <p>We make minimizing or eliminating your mortgage easy, in high-value real estate markets. </p>
            </div>
            <div className="learn_image">
                <img src={img} alt="" />
            </div>
        </div>
    )
}