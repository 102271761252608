import "./index.css"
import img from "../../assets/images/3.png"
import Theme_Buttom from "../general_components/button"

export default function OwnerShip({btn_link}) {
    return (
        <div className="theme_container">

            <div className="ownership_container">
                <div className="ownership_content">
                    <h2>WE MAKE TRUE HOME OWNERSHIP A REALITY</h2>
                    <h1>How Can Milenium Haus Eliminate My Mortgage? </h1>
                    <div className="ownership_image_mobile">
                        <img src={img} alt="" />
                    </div>
                    <p>Leveraging recent legislation with our pre-vetted legal expertise, allows us to build and refinance in record time.</p>
                    <p> SB 9 or AB 1033 in California are two bills that enable us to fast-track the approval process, enabling us to reparcel, split, or condo-ize parts of your property in record time.</p>
                    <p> You become a true homeowner, by owning most of your home, and either significantly reduce or eliminate your mortgage in the process.</p>
                    <div className="ow_btn">
                        <Theme_Buttom to={btn_link} text={'Explore More'} />
                    </div>
                </div>
                <div className="ownership_image">
                    <img src={img} alt="" />
                </div>
            </div>

        </div>
    )
}